/*---- External -------------------------------------------------------------*/

import React, { useEffect, useState } from 'react'

import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'

import ReactGA from 'react-ga'

import queryString from 'query-string'

import { ReactComponent as GearIcon } from 'bootstrap-icons/icons/gear-fill.svg'

/*---- Qualdesk -------------------------------------------------------------*/

import Steps from './Steps'

import YourDetails from './wizardSteps/YourDetails'
import YourOrg from './wizardSteps/YourOrg'
import UsingQualdesk from './wizardSteps/UsingQualdesk'
import YourStack from './wizardSteps/YourStack'
import OneMoreQuestion from './wizardSteps/OneMoreQuestion'

import useWizard from '../hooks/useWizard'

import { functions } from '../config/firebase'

import { ReactComponent as TwitterIcon } from '../assets/icons/twitter.svg'

import { SignupWizardState, WizardStep, SignupWizardStepId, SignupWizardStepIds } from '../types'

/*---------------------------------------------------------------------------*/

const Wizard: React.FC = () => {
  const steps: WizardStep<SignupWizardState, SignupWizardStepId>[] = [
    {
      id: SignupWizardStepIds.YOUR_DETAILS,
      title: 'Your details',
      submitEnabled: ({ name, email }) => !!name && !isEmpty(name, { ignore_whitespace: true }) && name.trim().length > 2 && !!email && isEmail(email),
      submitOnEnterKeyPress: true,
    },
    {
      id: SignupWizardStepIds.YOUR_ORG,
      title: 'Where you work',
      submitEnabled: ({ organization }) => !!organization && !isEmpty(organization, { ignore_whitespace: true }) && organization.trim().length > 2,
      submitOnEnterKeyPress: true,
    },
    {
      id: SignupWizardStepIds.USING_QUALDESK,
      title: 'Using Qualdesk',
    },
    {
      id: SignupWizardStepIds.YOUR_TOOL_STACK,
      title: 'Your tool stack',
    },
    {
      id: SignupWizardStepIds.ONE_MORE_QUESTION,
      title: 'One more question',
      handleSubmit: (wizardState) => functions.httpsCallable('signUp')(wizardState),
    }
  ]

  const [ done, setDone ] = useState(false)
  const [ loaded, setLoaded ] = useState(false)

  const handleDone = () => {
    setDone(true)
  }

  const wizard = useWizard({ steps, done: handleDone })
  const { index, handleSubmit, loading, onFirstStep, onLastStep, previous, step, submitEnabled, setWizardState, error } = wizard
  const { id, submitOnEnterKeyPress } = step

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_UA) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_UA)
      ReactGA.pageview('/sign-up', undefined, 'Sign up for Qualdesk')
    }
  }, [])

  const redirectIfNoEmailProvided = () => {
    const { email, ...rest } = queryString.parse(window.location.search)

    const newQueryString = queryString.stringify(rest, { skipEmptyString: true, skipNull: true })

    if ((!email || Array.isArray(email) || !isEmail(email))) {
      !!process.env.REACT_APP_MARKETING_SITE_HOME_URL && (window.location.href = process.env.REACT_APP_MARKETING_SITE_HOME_URL)
    }
    else {
      window.history.replaceState({}, '', !!newQueryString ? `/?${newQueryString}` : '/')
      setWizardState({ email })
      setLoaded(true)
    }
  }

  useEffect(redirectIfNoEmailProvided, [])

  const trackStep = () => {
    if (!!step) {
      ReactGA.event({
        category: 'Sign up',
        action: `Step viewed: ${step.title}`,
        label: step.id,
      })
    }
  }

  useEffect(trackStep, [ id ])

  useEffect(() => {
    if (!!done) {
      ReactGA.event({
        category: 'Sign up',
        action: 'Pre-launch signup complete',
      })
    }
  }, [ done ])

  const handleKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !!submitOnEnterKeyPress) {
      e.preventDefault()
      handleSubmit()
    }
  }

  if (!loaded) {
    return (
      <div className="flex-grow-1 bg-qualdesk-light-blue" />
    )
  }

  if (done) {
    return (
      <div className="flex-grow-1 d-flex flex-column overflow-hidden">
        <div className="wizard flex-grow-1 d-flex flex-column container my-sm-5 d-flex flex-column overflow-hidden">
          <div className="row border-bottom flex-shrink-0 bg-white">
            <Steps index={index + 1} steps={steps} />
          </div>
          <div className="row flex-grow-1 flex-shrink-1 overflow-auto bg-white p-3 p-sm-5">
            <div className="col d-flex flex-column justify-content-center">
              <h3>Thanks for signing up</h3>
              <p>We’ll be in touch soon to get you set up <span role="img" aria-label="woohoo">🚀</span></p>
              <p><a className="btn rounded-pill btn-qualdesk-light-blue text-primary d-inline-flex align-items-center" href="https://twitter.com/qualdesk"><TwitterIcon className="mr-1" width={12} height={12} /> <b>Qualdesk</b></a></p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex-grow-1 d-flex flex-column overflow-auto" onKeyPress={handleKey}>
      <div className="wizard flex-grow-1 d-flex flex-column container my-sm-5 d-flex flex-column">
        <div className="row border-bottom flex-shrink-0 bg-white">
          <Steps index={index} steps={steps} />
        </div>
        <div className="wizard-main row bg-white p-3 p-sm-5">
          <div className="col p-0">
          { id === SignupWizardStepIds.YOUR_DETAILS && <YourDetails {...wizard} /> }
          { id === SignupWizardStepIds.YOUR_ORG && <YourOrg {...wizard} /> }
          { id === SignupWizardStepIds.USING_QUALDESK && <UsingQualdesk {...wizard} /> }
          { id === SignupWizardStepIds.YOUR_TOOL_STACK && <YourStack {...wizard} /> }
          { id === SignupWizardStepIds.ONE_MORE_QUESTION && <OneMoreQuestion {...wizard} /> }
          </div>
        </div>
        <div className="wizard-cta row flex-shrink-0 pt-0 pt-sm-3 pb-3 px-3 d-flex justify-content-between align-items-center">
          { (!onFirstStep && !error) && <button className="btn btn-qualdesk-mid-gray" disabled={loading} onClick={previous}>Back</button> }
          { !!error && <span className="alert alert-warning mb-3 my-sm-0 p-2">{ error }</span> }
          {
            loading
            ? <button className="btn btn-primary ml-auto position-relative" disabled>
                <span style={{ opacity: 0 }}>Sign up</span>
                <span className="loading-spinner"><GearIcon style={{ height: '19px', width: '19px' }} /></span>
              </button>
            : <button className="btn btn-primary ml-auto" disabled={!submitEnabled || !!error} onClick={handleSubmit}>{ onLastStep ? 'Sign up' : 'Next' }</button>
          }
        </div>
      </div>
    </div>
  )
}

export default Wizard
