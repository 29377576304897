/*---- External -------------------------------------------------------------*/

import React, { Dispatch } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { SignupWizardStepProps, Tools, Tool, SignupWizardState } from '../../types'

/*---------------------------------------------------------------------------*/

const tools = Object.values(Tools).filter(k => typeof k === 'string').sort((a, b) => a.toLocaleString().localeCompare(b.toLocaleString())) as Tool[]

const halfwayIndex = Math.round(tools.length / 2)
const toolsColumnOne = tools.slice(0, halfwayIndex)
const toolsColumnTwo = tools.slice(halfwayIndex)

const YourStack: React.FC<SignupWizardStepProps> = ({ setWizardState, wizardState }) => (
  <>
    <div>
      <h5>Which tools do you use regularly at work?</h5>
    </div>
    <div className="mt-4">
      <div className="row">
        <div className="col">
        {
          toolsColumnOne.map(tool => (
            <IntegrationCheckbox tool={tool} setWizardState={setWizardState} wizardState={wizardState} key={tool} />
          ))
        }
        </div>
        <div className="col">
        {
          toolsColumnTwo.map(tool => (
            <IntegrationCheckbox tool={tool} setWizardState={setWizardState} wizardState={wizardState} key={tool} />
          ))
        }
        </div>
      </div>
      <div className="form-group mt-4 mb-0">
        <label htmlFor="other">Any others?</label>
        <textarea className="form-control" id="other" onChange={(e) => setWizardState({ currentStackOther: e.target.value })} value={wizardState.currentStackOther || ''} />
      </div>
    </div>
  </>
)

interface IntegrationProps {
  tool: Tool
  wizardState: SignupWizardState
  setWizardState: Dispatch<Partial<SignupWizardState>>
}

const IntegrationCheckbox: React.FC<IntegrationProps> = ({ tool, setWizardState, wizardState }) => (
  <div className="form-check mb-2" key={tool}>
    <input
      className="form-check-input"
      type="checkbox"
      value={tool}
      id={tool.toString()}
      checked={wizardState.currentStack && wizardState.currentStack[tool]}
      onChange={(e) => setWizardState({ currentStack: { ...wizardState.currentStack, [tool]: e.target.checked } })}
    />
    <label className="form-check-label" htmlFor={tool.toString()}>{tool}</label>
  </div>
)

export default YourStack
