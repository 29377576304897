/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { SignupWizardStepProps } from '../../types'

/*---------------------------------------------------------------------------*/

const YourOrg: React.FC<SignupWizardStepProps> = ({ setWizardState, wizardState }) => (
  <>
    <div className="form-group">
      <label htmlFor="organization">Your company or organization</label>
      <input autoFocus type="text" className="form-control" id="organization" onChange={(e) => setWizardState({ organization: e.target.value })} value={wizardState.organization || ''} />
    </div>
    <div className="form-group">
      <label htmlFor="jobTitle" aria-describedby="jobTitleHelp">Your job title <span className="text-muted">(optional)</span></label>
      <input className="form-control" id="jobTitle" onChange={(e) => setWizardState({ jobTitle: e.target.value })} value={wizardState.jobTitle || ''} />
      <small id="jobTitleHelp" className="form-text text-muted">Providing your job title will help us get you on board quickly <span role="img" aria-label="woohoo">🚀</span></small>
    </div>
  </>
)

export default YourOrg
