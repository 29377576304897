/*---- External -------------------------------------------------------------*/

import { useReducer, useState } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { WizardStep, Wizard } from '../types'

/*---------------------------------------------------------------------------*/

export interface WizardHookArguments<WizardState, WizardStepId> {
  steps: WizardStep<WizardState, WizardStepId>[]
  initialState?: WizardState
  done: () => void
}

function useWizard<WizardState, WizardStepId>({ steps, initialState, done }: WizardHookArguments<WizardState, WizardStepId>): Wizard<WizardState, WizardStepId> {
  const totalSteps = steps.length

  const [ index, setStep ] = useState(0)
  const [ wizardState, setWizardState ] = useReducer((state: WizardState, partial: Partial<WizardState>) => ({ ...state, ...partial } as WizardState), initialState || {} as WizardState)
  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState('')

  const step = steps[index]

  const onFirstStep = index === 0
  const onLastStep = index === totalSteps - 1

  const submitEnabled = typeof step.submitEnabled !== 'undefined' ? (
    typeof step.submitEnabled === 'function'
    ? step.submitEnabled(wizardState)
    : step.submitEnabled
  ) : true

  const findStepIndex = (id: WizardStepId) => steps.findIndex(s => s.id === id)

  const changeStep = (delta: number) => {
    setError('')
    setStep((current) => ((current + delta) % totalSteps))
  }

  const next = () => {
    if (!submitEnabled) {
      return false
    }
    if (typeof done === 'function' && onLastStep) {
      done()
    }
    else {
      changeStep(1)
    }
  }

  const previous = () => changeStep(-1)

  const go = (indexOrId: WizardStepId | number) => {
    if (typeof(indexOrId) === 'number') {
      setStep(indexOrId > totalSteps ? totalSteps - 1 : indexOrId)
    } else {
      setStep(findStepIndex(indexOrId))
    }
  }

  const handleSubmit = async () => {
    if (!!step.handleSubmit) {
      setLoading(true)
      
      try {
        const result = await step.handleSubmit(wizardState, setWizardState)

        if (!!result) {
          next()
          setLoading(false)
        }
      }
      catch (err) {
        console.error(err)
        setError('We’re having problems signing you up. Please try again later.')
        setLoading(false)
      }
    }
    else {
      next()
    }
  }
  
  return {
    step,
    onFirstStep,
    onLastStep,
    loading,
    index,
    handleSubmit,
    submitEnabled,
    previous,
    go,
    wizardState,
    setWizardState,
    error,
    setError,
  }
}

export default useWizard