/*---- External -------------------------------------------------------------*/

import React from 'react'
import 'bootstrap/dist/js/bootstrap.bundle.min'

/*---- Qualdesk -------------------------------------------------------------*/

import Header from './components/Header'
import Wizard from './components/Wizard'

import './styles/App.scss'

/*---------------------------------------------------------------------------*/

const App: React.FC = () => {
  return (
    <div className="h-100 d-flex flex-column">
      <Header />
      <Wizard />
    </div>
  )
}

export default App
