/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { ReactComponent as Logo } from '../assets/brand/qualdesk-logo.svg'

/*---------------------------------------------------------------------------*/

const Header: React.FC = () => {
  return (
    <nav className="flex-shrink-0 shadow-sm navbar navbar-expand-md navbar-light bg-white">
      <a href="https://www.qualdesk.com/">
        <Logo className="my-2" height="20" />
      </a>
    </nav>
  )
}

export default Header
