/*---- External -------------------------------------------------------------*/

import React, { PropsWithChildren } from 'react'

import { ReactComponent as TickIcon } from 'bootstrap-icons/icons/check.svg'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { WizardStep } from '../types'

/*---------------------------------------------------------------------------*/

interface StepsProps<WizardState, WizardStepId> {
  index: number
  steps: WizardStep<WizardState, WizardStepId>[]
}

const Steps = <WizardState, WizardStepId>({ index, steps }: PropsWithChildren<StepsProps<WizardState, WizardStepId>>) => (
  <div className="steps w-100 d-flex justify-content-around bg-light">
  {
    steps.map(({ title }, i) => (
      <div className={classNames('step d-flex flex-column align-items-center my-3', { active: index === i, done: index > i })} key={i}>
        <div
          className={classNames('step-number rounded-circle shadow-sm text-white d-flex flex-column align-items-center justify-content-center', {
            'bg-primary': index === i,
            'bg-qualdesk-green text-qualdesk-dark-green': index > i,
            'bg-secondary': index < i,
          })}
          style={{ height: '30px', width: '30px' }}
        >
          { index > i ? <TickIcon style={{ height: '25px', width: '25px' }} /> : i + 1 }
        </div>
        <div className="step-label d-none d-sm-block mx-4 mt-2 text-center text-xs font-weight-bold">{ title }</div>
      </div>
    ))
  }
  </div>
)

export default Steps