/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { SignupWizardStepProps, UseCases, UseCase } from '../../types'

/*---------------------------------------------------------------------------*/

const useCases = Object.values(UseCases).filter(k => typeof k === 'string') as UseCase[]

const UsingQualdesk: React.FC<SignupWizardStepProps> = ({ setWizardState, wizardState }) => (
  <>
    <div>
      <h5>What are you interested in using Qualdesk for?</h5>
      <div className="mt-2"><small className="text-muted">Don’t worry – you don’t have to make any commitments at this stage, but your answers here will help us to get you set up, and to plan our own roadmap.</small></div>
    </div>
    <div className="mt-4">
      {
        useCases.map(useCase => (
          <div className="form-check mb-2" key={useCase}>
            <input
              className="form-check-input"
              type="checkbox"
              value={useCase}
              id={useCase.toString()}
              checked={wizardState.useCases && wizardState.useCases[useCase]}
              onChange={(e) => setWizardState({ useCases: { ...wizardState.useCases, [useCase]: e.target.checked } })}
            />
            <label className="form-check-label" htmlFor={useCase.toString()}>{useCase}</label>
          </div>
        ))
      }
      <div className="form-group mt-4 mb-0">
        <label htmlFor="other">Something else:</label>
        <textarea className="form-control" id="other" onChange={(e) => setWizardState({ useCaseOther: e.target.value })} value={wizardState.useCaseOther || ''} />
      </div>
    </div>
  </>
)

export default UsingQualdesk
