/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { SignupWizardStepProps } from '../../types'

/*---------------------------------------------------------------------------*/

const YourDetails: React.FC<SignupWizardStepProps> = ({ setWizardState, wizardState }) => (
  <div className="d-flex flex-column h-100">
    <div className="form-group">
      <label htmlFor="name">Your name</label>
      <input autoFocus type="text" className="form-control" id="name" onChange={(e) => setWizardState({ name: e.target.value })} value={wizardState.name || ''} />
    </div>
    <div className="form-group">
      <label htmlFor="email" aria-describedby="emailHelp">Your work email address</label>
      <input type="email" className="form-control" id="email" onChange={(e) => setWizardState({ email: e.target.value })} value={wizardState.email || ''} />
      <small id="emailHelp" className="form-text text-muted">Using your work email address will help us get you on board quickly <span role="img" aria-label="woohoo">🚀</span></small>
    </div>
    <div className="mt-3 mb-3 mt-sm-auto mb-sm-0"><small className="text-muted">The data you provide during this sign up process will be stored and processed in accordance with our <a className="text-muted" href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer"><u>privacy and cookies policy</u></a>.</small></div>
  </div>
)

export default YourDetails
