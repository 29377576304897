/*---- External -------------------------------------------------------------*/

import { Dispatch, PropsWithChildren } from 'react'

import { functions } from 'firebase'

/*---- Qualdesk -------------------------------------------------------------*/



/*---------------------------------------------------------------------------*/

export interface Wizard<WizardState, WizardStepId> {
  step: WizardStep<WizardState, WizardStepId>
  onFirstStep: boolean
  onLastStep: boolean
  loading: boolean
  index: number
  handleSubmit: () => void
  submitEnabled: boolean
  previous: () => void
  go: (indexOrId: WizardStepId | number) => void
  wizardState: WizardState
  setWizardState: Dispatch<Partial<WizardState>>
  error: string
  setError: Dispatch<React.SetStateAction<string>>
}

export type WizardSubmit<WizardState> = (state: WizardState, setWizardState: Dispatch<Partial<WizardState>>) => Promise<boolean | string | functions.HttpsCallableResult>

export interface WizardStep<WizardState, StepId> {
  id: StepId
  title: string
  handleSubmit?: WizardSubmit<WizardState>
  submitEnabled?: (state: WizardState) => boolean
  submitOnEnterKeyPress?: boolean
}

export enum SignupWizardStepIds {
  YOUR_DETAILS = 'YOUR_DETAILS',
  YOUR_ORG = 'YOUR_ORG',
  USING_QUALDESK = 'USING_QUALDESK',
  YOUR_TOOL_STACK = 'YOUR_TOOL_STACK',
  ONE_MORE_QUESTION = 'ONE_MORE_QUESTION',
}

export type SignupWizardStepId = keyof typeof SignupWizardStepIds

export enum Tools {
  'Airtable',
  'Google G Suite',
  'Microsoft Office 365',
  'Jira',
  'Linear',
  'GitHub',
  'Asana',
  'GitLab',
  'Bitbucket',
  'productboard',
  'monday.com',
  'Notion',
  'Evernote',
  'Trello',
}

export type Tool = keyof typeof Tools

type ExistingStackSelection = {
  [key in Tool]?: boolean
}

export enum UseCases {
  'Sprint planning',
  'Roadmap planning',
  'Estimation',
  'Retrospectives',
  'Standups',
  'Ideation',
  'User research analysis',
}

export type UseCase = keyof typeof UseCases

type UseCaseSelection = {
  [key in UseCase]?: boolean
}

export interface SignupWizardState {
  name?: string
  email?: string
  organization?: string
  jobTitle?: string
  useCases?: UseCaseSelection
  useCaseOther?: string
  currentStack?: ExistingStackSelection
  currentStackOther?: string
  why?: string
}

export type SignupWizardStepProps = PropsWithChildren<Wizard<SignupWizardState, SignupWizardStepId>>
