/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { SignupWizardStepProps } from '../../types'

/*---------------------------------------------------------------------------*/

const OneMoreQuestion: React.FC<SignupWizardStepProps> = ({ setWizardState, wizardState }) => (
  <>
    <div>
      <h5>Why are you interested in using Qualdesk?</h5>
      <div className="mt-2"><small className="text-muted">This is totally optional but will help us to prioritize access and with our research.</small></div>
    </div>
    <div className="mt-4">
    <textarea className="form-control" id="other" onChange={(e) => setWizardState({ why: e.target.value })} value={wizardState.why || ''} />
    </div>
  </>
)

export default OneMoreQuestion
