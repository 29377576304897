import app from 'firebase/app'
import 'firebase/functions'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  region: process.env.REACT_APP_FIREBASE_REGION,
}

const runningLocally = window.location.hostname === "localhost" || window.location.hostname.startsWith('192.168.')

if (runningLocally) {
  config.databaseURL = `http://${window.location.hostname}:9000?ns=${process.env.REACT_APP_FIREBASE_PROJECT_ID}`
}

const firebase = app
const firebaseApp = firebase.initializeApp(config)
const functions = firebaseApp.functions(config.region)

if (runningLocally) {
  functions.useFunctionsEmulator(`http://${window.location.hostname}:5001`)
} 

export default firebaseApp
export { functions }